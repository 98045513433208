import MP4Box from "mp4box";

const video = {}

video.checkH264 = function (file) {

    return new Promise((resolve, reject) => {

        const chunkSize = 1024 * 1024 * 5; // 每次读取 5MB

        const mp4box = MP4Box.createFile();

        let offset = 0;

        const readChunk = function () {

            const chunk = file.slice(offset, offset + chunkSize);

            const fileReader = new FileReader();

            fileReader.onload = function (e) {
                const arrayBuffer = e.target.result;
                arrayBuffer.fileStart = offset;
                mp4box.appendBuffer(arrayBuffer);
                offset += chunkSize;
                if (offset < file.size) {
                    readChunk();
                } else {
                    mp4box.flush();
                }
            };

            fileReader.readAsArrayBuffer(chunk);
        }

        mp4box.onReady = function (info) {

            const codecs = mp4box.getCodecs()

            mp4box.flush();

            if (codecs.indexOf('avc') === -1) {
                reject('请上传H.264（AVC）编码的mp4文件')
            }

            console.log('video format check ok!');
            resolve(info);

        };

        readChunk()
    })
}

export default video;